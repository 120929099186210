<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
 src="https://www.hanwha-pm.com/upload/product/20210517/184512qAvl.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>

The DECAN series models can handle a wide range of components and maximize productivity with various PCB transfer systems.
</h2>  
                <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Secures the applicability to components and conveyor flexibility.
                      </h5>
                    </li>
                    <li>
                      <h5> Reduces production time by optimizing the motion sequence.</h5>
                    </li>
                    <li>
                      <h5>
                       Reinforces odd-shape component recognition by applying 3D lighting system.
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size: 18px">
              <div class="container-fluid">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      
                      <tr>
                        <th scope="row">Number of Spindles</th>
                        <td style="font-size: 12px">10 Spindle x 2 Gantry</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Speed</th>
                        <td style="font-size: 12px">92,000 CPH (Optimum)</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Accuracy</th>
                        <td style="font-size: 12px">
                         ±28 μm @ Cpk ≥ 1.0 (03015 Chip) / ±25 μm @ Cpk ≥ 1.0(IC)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Fly Camera</th>
                        <td style="font-size: 12px">
                        03015 ~ □12 mm
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style="width: 400px">Fix Camera</th>
                        <td style="font-size: 12px">~□42 mm (Standard) / ~□55 mm (MFOV) / ~ L75 mm Connector (MFOV)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br /><br />
                <h1><strong>Features</strong></h1>
                <br /><br />

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_6.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong>
High Performance</strong>
                      </h1>
                      <h3>
                        <strong>Productivity Improvement</strong>
                      </h3>
                      <p>
Increases the speed and accuracy for placing microchips (03015) Applies dual-lane PCB transfer system (productivity increases by 15% compared to a single lane)
              </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_1.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong>

High Reliability </strong>
                      </h1>
                      <h3>
                        <strong>
                         Adopts a Linear Motor to Realize Low Noise and Vibration</strong
                        >
                      </h3>
                      <p>
Applies the twin servo control and linear motor to the Y-axis to realize low noise and vibration.           </p>         </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_8.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 

Flexible Production</strong>
                      </h1>
                      <h3><strong> Supporting Modular Conveyor System for Various Production Environments
</strong></h3>
                      <p>
Possible to produce various models depending on the Modular Conveyor System replaceable on site.                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_3.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Easy Operation</strong>
                      </h1>
                      <h3>
                        <strong>Reinforces the Convenience of Machine Software Operation</strong>
                      </h3>
                      <p>
The built-in optimization software allows a PCB program to be easily created and edited.                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>